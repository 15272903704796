.row_videos {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: inline-block;
    flex-wrap: wrap;
    position: relative;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
    align-items: center;
    justify-content: center;
    padding: 10px 0 0 15px;
}

@media (min-width: 1024px) {
    .container {
        max-width: 850px;
    }
}



@media (min-width: 1600px) {
    .container {
        max-width: 1400px;
    }
}


@media (max-width: 560px) {
    .container {
        width: 300px;
    }
}