.content-video {
    /* 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(7, 0, 0); */
   
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: black;
    display: block;
    /* background-color: black;  *//* Fallback para dispositivos que no reproducen video */
}

#video1 {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    object-fit: cover;
  /* transform: translate(-10%, -10%) scale(1.1); */ /* Centrado por defecto */
  /* transition: transform 0.1s ease-out; */ /* Suavidad del movimiento */
}