.scroll-to-top {
    position: fixed;
    bottom: 30px;
    left: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  
  .scroll-to-top:hover {
    background-color: #0056b3;
  }