body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6C757D;
  text-align: left;
  background-color: #000;
  font-display: swap; /* Para fuentes web */
}
.text-truncate { white-space: inherit !important;}

.text-dark2 {color:#000 !important}

h3,h4{
  color:#000 !important;
}

.btn.btn-primary {
  color: #ffffff !important;
}

.bg-light {
  background-color: white !important;
}

.flex-fill,h6 {
  color: black !important;
}

h6:hover, .h6:hover {
  color: #0062f3 !important;
}.h6 {
  color: #000;
}

.cat-cat:hover h6, .h6 {
  color: #fff !important;
}.cat-cat h6, .h6 {
  color: #000 !important;
}

.cat-cat:hover {
  background: #0062f3;  
}

.cat-cat img {
  transition: .5s;
}

.cat-cat:hover img {
  transform: scale(1.2) rotate(5deg);
}

.cat-cat:hover .h6 {
  color: #fff;
}

.cat-cat:hover .text-body{
  color: #fff !important;
}

.cat-item:hover {
  background: #0062f3;  
}

.cat-item img {
  transition: .5s;
}

.cat-item:hover img {
  transform: scale(1.2) rotate(5deg);
}

.cat-item:hover .h6 {
  color: #fff;
}

.cat-item:hover .text-body{
  color: #fff !important;
}

.cat-item {
  background: #a1c3f4;
  transition: .5s;
}

.cat-cat {
  background: #a1c3f4;
  transition: .5s;
}


.cat-cat:hover {
  background: #0062f3;  
}

.cat-cat img {
  transition: .5s;
}

.cat-cat:hover img {
  transform: scale(1.2) rotate(5deg);
}

.cat-cat:hover .h6 {
  color: #fff;
}

.cat-cat:hover .text-body{
  color: #fff !important;
}

.cat-cat {
  background: #0062f3;
  transition: .5s;
}

section {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

/* .h2{
  color: black !important;
} */

li{
  color: black !important;
}

/* a:hover {
  color: #fff !important;

}
 */
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #212529;
/*   background-color: #0062f3 !important;
  border-color: #fff !important; */
}
.product-item .btn:hover {
  color: #fff !important;
}

.btn-primary {
  color: #fff !important;;
  background-color: #0062f3 !important;;
  border-color: #58bae8 !important;;
}

.btn-primary:hover {
  color: #fff !important;;
  background-color: #0062f3 !important;;
  border-color: #58bae8 !important;;
}