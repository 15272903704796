.mini-gallery {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    gap: 10px;
    background-color: #007bff;
  }
  
  .mini-gallery-item {
    width: 60px;
    height: 60px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border-color 0.3s;
    
    border-color: #fff;
  }
  
  .mini-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .mini-gallery-item:hover {
    border-color: #eae551;
  }
  
  