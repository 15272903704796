.text-yellow {
    color: #FCFF52 !important;
    text-shadow: -1px 0 #E26A1C, 0 2px #E26A1C, 2px 0 #E26A1C, 0 -1px #E26A1C;
    text-align: center;
}

.fondoColorDegrades {
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,98,243,1) 35%, rgba(0,212,255,1) 100%);
height: 200px;
}

.fondoColorDegradesW {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,98,243,1) 33%, rgba(255,255,255,1) 100%);
    height: 200px;
    }