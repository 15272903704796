.policy-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .policy-section h2 {
    background-color: #0062f3; /* Fondo azul */
    color: white; /* Texto blanco */
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .policy-item {
    margin-bottom: 40px;
  }
  
  .policy-item p {
    text-align: justify;
    line-height: 1.6;
    font-size: 1rem;
  }
  
  .policy-item ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 20px;
  }
  
  .policy-item ul li {
    margin-bottom: 10px;
    font-size: 0.95rem;
    padding-left: 20px;
    position: relative;
  }
  
  .policy-item ul li::before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    color: #0062f3;
    font-size: 1.2rem;
    line-height: 1.2;
  }