.share-button-container {
    text-align: center;
  }
  
  .share-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 18px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .share-button:hover {
    background-color: #0056b3;
  }
  
  .share-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .share-popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .share-popup h3 {
    margin-bottom: 20px;
  }
  
  .share-options {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .share-options li {
    margin-bottom: 10px;
  }
  
  .share-options a,
  .share-options button {
    text-decoration: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .share-options a:hover,
  .share-options button:hover {
    text-decoration: underline;
  }
  
  .close-popup {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .close-popup:hover {
    background-color: #a71d2a;
  }
  
