.text-secondary {
  color: #F5F5F5 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
    color: #cfcfcf !important;
  }

  
.pt-5, .py-5 {
  padding-top: 3rem !important;
}
.mt-5, .my-5 {
  margin-top: 3rem !important;
}
.bg-dark {
  background-color: #2b3a42 !important; /*#3D464D*/
}
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* .text-secondary {
    color: #F5F5F5 !important;
} */
.text-secondary-footer-h5 {
    color: #F5F5F5 !important;
    font-weight: 500 !important;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #3D464D;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.text-secondary-footer-h6 {
    color: #F5F5F5 !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #3D464D;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.text-secondary:hover {
    color: #cfcfcf !important;
    /* text-decoration: underline; */
}

.text-secondary-footer-h5:hover, .text-secondary-footer-h5:focus {
    color: #cfcfcf !important;
  }

.text-secondary-footer-h6:hover, .text-secondary-footer-h6:focus {
   color: #cfcfcf !important;
  }

.text-uppercase-footer {
    text-transform: uppercase !important;
}
.mb-4-footer, .my-4-footer {
    margin-bottom: 1.5rem !important;
}

.mb-5, .my-5 {
    margin-bottom: 3rem !important;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.mb-2, .my-2 {
    margin-bottom: 0.5rem !important;
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.row {
    display: flex
;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    vertical-align: middle;
    border-style: none;
}

.text-center {
    text-align: center !important;
}

.text-primary-footer {
   /*  color: #FFD333 !important; */
   color: #ffcc00 !important;
}
.mr-3, .mx-3 {
    margin-right: 1rem !important;
}
.fa, .fas {
    font-weight: 900;
}
.fa, .far, .fas {
    font-family: "Font Awesome 5 Free";
}
.fa, .fab, .fad, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.pb-4, .py-4 {
    padding-bottom: 1.5rem !important;
}
.pt-4, .py-4 {
    padding-top: 1.5rem !important;
}
.border-top {
    border-top: 1px solid #dee2e6 !important;
}