.data-grid-container {
    position: relative; /* Fija el overlay para que siempre esté en la ventana */
    padding: 20px;
    font-family: Arial, sans-serif;
    z-index: 2;
  }
  
  .data-grid {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .data-grid th, .data-grid td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .data-grid td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    background-color: #fff;
  }

  .data-grid th {
    background-color: #007bff;
    color: white;
  }
  
  .btn-action {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 1px;
  }
  
  .btn-action:hover {
    background-color: #0056b3;
  }
  
    
  .btn-ver:hover {
    background-color: #1c7130;
  }

  .btn-ver {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 1px;
  }

      
  .btn-pdf:hover {
    background-color: #a82c2c;
  }

  .btn-pdf {
    background-color: #ee3d3d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }

 .popup-overlay {
    position: fixed; /* Asegura que el popup esté fijo en la ventana */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Mayor que cualquier otro elemento de tu página */
  }
  
  .popup-form {
    background: white;
  padding: 20px;
  border-radius: 8px;
/*   width: 600px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 999; /* Más alto que el overlay */
  position: relative;
  }
  
  .popup-form h3 {
    margin-top: 0;
    color: #007bff;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .form-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-actions button:first-child {
    background-color: #007bff;
    color: white;
  }
  
  .form-actions button:last-child {
    background-color: #dc3545;
    color: white;
  }
  
  body.popup-open {
    overflow: hidden; /* Bloquea el scroll en la página */
  }


  
  .form-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Dos columnas iguales */
    gap: 20px; /* Espacio uniforme entre los elementos */
    padding: 20px;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  
  .form-group input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%; /* Asegura que ocupen todo el ancho disponible */
  }
  
  @media (max-width: 768px) {
    .form-container {
      grid-template-columns: 1fr; /* Pasa a una sola columna en pantallas pequeñas */
    }
  }