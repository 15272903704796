.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.8); */ /* Fondo semitransparente */
    /* background-color: rgb(248, 248, 248); */
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que esté sobre todo */
  }
  