.unsubscribe-container {
    /* display: flex;
    
    transform: translate(-40%, 10%); */
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
    margin-top: -100px;
    margin-bottom: -200px;
  }
  
  .message-box {
    text-align: center;
    max-width: 400px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  @media (max-width: 480px) { 

    .unsubscribe-container {
        margin-top: 24rem;
        left: 0;
        right: 0;
        transform: translate(0%, -45%);
    }

    .message-box {
      max-width: 95%; /* Ocupa todo el ancho disponible */
      padding: 15px; /* Reduce el espacio interno */
      margin: 0 auto; /* Agrega espacio lateral */
    }


  .message-box h1 {
    font-size: 20px;
  }
  
  .message-box p {
    font-size: 14px;
    line-height: 1.5; /* Ajusta el espaciado entre líneas */
  }
}

.message-box h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .message-box p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .subscribe-button {
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .subscribe-button:hover {
    background-color: #005bb5;
    color: white;
  }


  .unsubscribe-confirm-button {
    background-color: #ff0c00;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding-right: 5px;
  }
  
  .unsubscribe-confirm-button:hover {
    background-color: #dc0c01;
    color: white;
  }