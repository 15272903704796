.text-light {color: #f8f9fa !important;}.text-center {text-align: center !important;}.bg-dark {background-color: #343a40 !important;}.section-title {font-size: 3rem !important;}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%; 
    justify-content: center; 
    align-items: center;
   /*  min-height: 100vh;  *//* Altura mínima para evitar saltos */
}
