.container-login {
    max-width: 340px;
    margin: auto;
    padding: 1.5rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo-container {
    text-align: center;
    margin-bottom: 1rem;
}

.logo {
    width: 120px;
    height: auto;
}

/* .text-secondary {
    color: #000 !important;
    text-decoration: none;
    font-size: 0.9rem;
}

.text-secondary:hover {
    color: yellow !important;
    text-decoration: underline;
} */

.mb-3 {
    margin-bottom: 0.8rem !important;
}

.text-body-secondary {
    font-size: 0.8rem;
}

/* .w-100 {
    padding: 0.5rem;
} */